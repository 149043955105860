<template>

<Header />


<section style="padding-top:150px; padding-bottom: 100px; ">
	

	<div class="container">


<h1>TERMS & CONDITIONS FOR AGENTS/OWNERS</h1>
    
    <h2>Basic Amenities To Be Provided to Clients:</h2>
    <ul>
        <li>Ensure the provision of WiFi access during your stay to facilitate seamless connectivity.</li>
        <li>Each accommodation is to be equipped with a Smart TV for client’s entertainment needs.</li>
        <li>Ensure the apartment has adequate parking space available to accommodate client’s vehicle comfortably.</li>
        <li>Regular housekeeping services are to be provided to maintain cleanliness and tidiness. This also ensures we can track that the client is taking care of the apartment.</li>
        <li>Ensure the kitchen in your accommodation is fully equipped to meet client’s culinary requirements.</li>
    </ul>
    
    <h2>Photos and Videos Usage Agreement:</h2>
    <p>By utilizing our services, you grant us the right to use all photos and videos posted on our website and various platforms for sales and marketing activities. This includes, but is not limited to, promotional materials, social media posts, and advertising campaigns.</p>
    
    <h2>Cancellation Policy:</h2>
    <p>In the event of unforeseen and uncontrollable circumstances that prevent us from accommodating you in a selected property, we will make every effort to provide an alternative property of similar stature.</p>
    <p>All reservations are fully refundable if cancelled one week prior to the scheduled check-in date. If cancelled less than a week before check-in, a 50% refund will be issued. Cancellations made 48 hours before check-in, or in the event of a stay being cut short, will be 100% chargeable.</p>
    
    <h2>Payments:</h2>
    <p>All payments will be processed within 24 hours after the client has checked in/payment and remitted to the platform specified by you.</p>
    <p>Your payment details should be communicated to us through an official email.</p>
    
    <h2>Acceptance of Terms:</h2>
    <p>By clicking "Accept" on our website, you acknowledge and agree to the terms of engagement outlined in these Terms & Conditions. It is important to read and understand these terms before proceeding with our services. If you have any questions or concerns, please contact us for clarification.</p>


		

		
	</div>


</section>

<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'

	
	export default{
		name : 'home',
		components : {Header,Footer}
	
	}

</script>