<template>

<Header />



<section style="padding-bottom:100px; padding-top: 150px;">
	
	<div class="container">

		<div class="row">
			
			<div class="col-md-3"></div>
			<div class="col-md-6">

				<div>
					<router-link to="/dashboard" class="btn p-0"> <i class="bi bi-arrow-left color-1"></i> Dashboard</router-link>
				</div>

				<form @submit.prevent="change_profile()">
					
					<div class="row">

						<div class="col-md-6 mt-2">
							<label>First name</label>
							<input type="text" v-model="first_name" class="form-control">
						</div>

						<div class="col-md-6 mt-2">
							<label>Last name</label>
							<input type="text" v-model="last_name" class="form-control">
						</div>


						<div class="col-md-6 mt-2">
							<label>Email</label>
							<input type="email" v-model="email" class="form-control">
						</div>


						<div class="col-md-6 mt-2">
							<label>Phone</label>
							<input type="text" v-model="phone" class="form-control">
						</div>

						<div class="col-md-12 mt-2">
							<label>Password</label>
							<input type="password" v-model="password" class="form-control">
						</div>

						<div class="col-md-6 mt-3">
						<button class="btn btn-1">Save changes</button>
						</div>
						
					</div>

				</form>
				
			</div>

		</div>
		
	</div>


</section>




<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'
	import Swal from 'sweetalert2'


	
	export default{
		name : 'home',
		components : {Header,Footer},
		data(){
			return{
				first_name : this.$store.state.first_name,
				last_name : this.$store.state.last_name,
				email : this.$store.state.email,
				phone : this.$store.state.phone,
				password : ''

			}
		},

		methods : {

			async change_profile(){
				if(!this.first_name){
					Swal.fire({
			  title: "Error",
			  text: "First name is required",
			  icon: "error"
			});
					return
				}

				if(!this.last_name){
					Swal.fire({
			  title: "Error",
			  text: "Last name is required",
			  icon: "success"
			});
					return
				}

				if(!this.email){
					Swal.fire({
			  title: "Error",
			  text: "Email is required",
			  icon: "error"
			});
					return
				}

				if(!this.phone){
					Swal.fire({
			  title: "Error",
			  text: "Phone is required",
			  icon: "error"
			});
					return
				}

				const res = await axios.post(this.$store.state.url+'api/change-profile',{
					id : this.$store.state.id,
					first_name : this.first_name,
					last_name : this.last_name,
					email : this.email,
					phone : this.phone,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//pass
					
				})
				
				Swal.fire({
			  title: "Success",
			  text: "Changes saved",
			  icon: "success"
			});

			}

		},
		created(){

		}
	}

</script>