<template>


<section>
	

	<div class="container">

		<div class="row">

			<div class="col-md-6" style="background-image: url('/assets/images/portrait-interracial-couple-home (1).jpg'); background-size: cover; background-position: center; "></div>

			<div class="col-md-5">
				
				<div style="padding-top: 50px; padding-bottom: 50px;">

					<div style="text-align: right;">
						<router-link to="/" class="btn btn-outline-danger"> <i class="bi bi-chevron-left color-1"></i> Back to main site</router-link>
					</div>

					<div style="padding-top: 10px; text-align: center;">
						<img src="/assets/images/new-logo.png" style="height: 70px;">
						<h3><b>Welcome Back!</b></h3>
						<h4>Register</h4>
					</div>
					
					<form @submit.prevent="register()">

						<div class="row">


							<div class="col-md-6">
								<div class="mt-3"> 
							<label>First name <span class="text-danger">*</span> </label>
							<input v-model="first_name" class="form-control" placeholder="Enter first name" type="text">
						</div>
								
							</div>



							<div class="col-md-6">
								<div class="mt-3"> 
							<label>Last name <span class="text-danger">*</span> </label>
							<input v-model="last_name" class="form-control" placeholder="Enter last name" type="text">
						</div>
								
							</div>



							<div class="col-md-6">
								<div class="mt-3"> 
							<label>Email address <span class="text-danger">*</span> </label>
							<input v-model="email" class="form-control" placeholder="Enter email address" type="text">
						</div>
								
							</div>

							<div class="col-md-6">
								<div class="mt-3"> 
							<label>Phone <span class="text-danger">*</span> </label>
							<input v-model="phone" class="form-control" placeholder="Enter phone number" type="text">
						</div>
								
							</div>



							<div class="col-md-12">
								<div class="mt-3"> 
							<label>ID or Passport No. <span class="text-danger">*</span> </label>
							<input v-model="id_number" class="form-control" placeholder="Enter your ID or Passport No." type="text">
						</div>
								
							</div>


							<div class="col-md-12">

								<div class="mt-3"> 
							<label>Password <span class="text-danger">*</span> </label>
							<input v-model="password" class="form-control" placeholder="Enter password" type="password">
						</div>
								
							</div>


							<div class="col-md-12">


						<div class="mt-2">
							
							<div class="mb-3">
								<input type="checkbox" required> <button style="background-color:transparent; border:none; " class="p-0" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">I agree to <span class="color-1">terms & condtions </span> </button>
							</div>


							<button :disabled="disabled" class="btn btn-1 w-100">{{text}} </button>
						</div>


						<div class="mt-3">
							<router-link class="btn p-0" to="/login">Already have an account? <span class="text-primary">Login</span> </router-link>
						</div>
								
							</div>
							
						</div>
					
						

						

					


					</form>

				</div>

			</div>
			
			
		</div>
		
	</div>


</section>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Terms & Conditions</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
        <embed src="/assets/docs/Clients Terms and Conditions.pdf" style="width: 100%;"></embed>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>





</template>



<script>
	import axios from 'axios'
	import Swal from 'sweetalert2'

	export default{
		name  : 'login',
		data(){
			return{
				email : '',
				password : '',
				first_name : '',
				last_name : '',
				phone : '',
				id_number : '',
				disabled : false,
				text : 'Register'
			}
		},

		methods : {
			async register(){

				if(!this.first_name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "First name is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.last_name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Last name is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.phone){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Phone is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				if(!this.email){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "ID or passport is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.email){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Email is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}


				if(!this.password){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Password is required",
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				this.disabled = true
				this.text = 'Please wait...'


				const res = await axios.post(this.$store.state.url+'api/register-customer',{
					email : this.email,
					password : this.password,
					first_name : this.first_name,
					last_name : this.last_name,
					id_number : this.id_number,
					phone : this.phone
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='duplicate'){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "An account with that email already exists",
					  showConfirmButton: false,
					  timer: 1500
					});
				}else if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Account created successfully.Use your email and password to login.",
					  showConfirmButton: true,
					  
					});
				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Something went wrong",
					  showConfirmButton: false,
					  timer: 1500
					});
				}

				this.disabled = false
				this.text = 'Login'
				

			}
		}
	}

</script>