<template>

<Header />


<section style="padding-top:150px; padding-bottom: 100px; ">
	

	<div class="container">


		<h1>TERMS & CONDITIONS FOR CLIENTS</h1>
    
    <h2>Identification:</h2>
    <p>Clients are required to present valid identification to the security personnel at the main gate for security purposes and to comply with the law (Section 7 Private Security Act No. 13 of 2016).</p>
    
    <h2>No Smoking:</h2>
    <p>We enforce a strict no-smoking policy within our properties. Smoking is only permitted on designated balconies.</p>
    
    <h2>Parties:</h2>
    <p>We have a firm policy prohibiting any parties within our accommodations. This is to maintain a peaceful environment for all guests.</p>
    
    <h2>Pets:</h2>
    <p>Permission for pets varies by apartment. Please inquire about pet policies as it depends on the specific property.</p>
    
    <h2>Payments:</h2>
    <p>To reserve an apartment, a deposit is required. The remaining balance must be settled in full upon check-in.</p>
    
    <h2>General Cancellation Policy:</h2>
    <p>In the event of unforeseen and uncontrollable circumstances preventing accommodation in the selected property, we will make every effort to provide an alternative property of similar stature.</p>
    <p>Reservations are fully refundable if cancelled one week prior to the scheduled check-in date. If cancelled less than a week before check-in, a 50% refund will be issued.</p>
    <p>Cancellations made 48 hours before check-in or in the event of a stay being cut short will be 100% chargeable.</p>
    
    <h2>Early Bookings Cancellation Policy:</h2>
    <p><strong>Definition:</strong> Early Bookings are Bookings Made More than 1 Month to Check-In Dates</p>
    <ul>
        <li>Less than 48 hrs from the date of payment - 100% refund of the total amount paid</li>
        <li>3-14 days from the date of payment - 50% refund of the total amount paid</li>
        <li>15-21 days from the date of payment - 15% refund of the total amount paid</li>
    </ul>
    
    <h2>Acceptance of Terms:</h2>
    <p>By clicking "Accept" on our website or by booking our services, you acknowledge and agree to the terms of engagement outlined in these Terms & Conditions. It is crucial to read and understand these terms before proceeding with our services. If you have any questions or concerns, please contact us for clarification.</p>


		

		
	</div>


</section>

<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'

	
	export default{
		name : 'home',
		components : {Header,Footer}
	
	}

</script>