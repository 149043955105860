<template>

<Header />


<section style="padding-top:150px; padding-bottom: 100px; background-image: url('/assets/images/hero-background-icons.jpeg'); background-size: contain; background-position: top; ">
	

	<div class="container">

		<div style="text-align:center;">
			<h6 class="animate__animated animate__pulse">Home <span class="color-1">></span> About </h6>
			<h1 class="animate__animated animate__pulse">Who We Are</h1>
		</div>


		<div class="row">

			<div class="col-md-2"></div>
			<div class="col-md-8">
				
				<div style="text-align:center;">


					<div style="background-image:url('/assets/images/young-family-enjoying-trips (1).jpg'); width: 100%; background-position: center; background-size:cover; min-height: 400px;" class="rounded mt-3 mb-3 shadow animate__animated animate__pulse"></div>



					<div style="padding-top:30px;">
						<p class="animate__animated animate__pulse">
						 As a registered company in Kenya, Safiri by Kenyans is committed to providing you with the comfort and convenience you need during your travels. Our executive properties are located in major towns and are very affordable, making us the ideal choice for short or long-term stays. 
					</p>

					<p class="animate__animated animate__pulse">
						 Our fully furnished apartments come in a range of sizes, from cozy studios to spacious two-bedroom apartments, and are equipped with all the amenities you need to make your stay enjoyable. Our dedicated team of professionals is committed to providing exceptional customer service and ensuring your stay is stress-free. 
					</p>

					</div>


					<router-link class="btn" to="/contact">Contact Us <i class="bi bi-arrow-right color-1"></i> </router-link>
					
				</div>

			</div>
			<div class="col-md-2"></div>
			
		</div>

		
	</div>


</section>

<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'

	
	export default{
		name : 'home',
		components : {Header,Footer}
	
	}

</script>