import { createStore } from 'vuex'

export default createStore({
  state: {
    url : 'https://server.safiribykenyans.co.ke/',
    first_name : localStorage.getItem('first_name'),
    last_name : localStorage.getItem('last_name'),
    email : localStorage.getItem('email'),
    phone : localStorage.getItem('phone'),
    id : localStorage.getItem('id'),
    logged_in : localStorage.getItem('logged_in'),
    picked_apartment : localStorage.getItem('picked_apartment'),
    total_amount : localStorage.getItem('total_amount'),
    days : localStorage.getItem('days'),
    check_in_date : localStorage.getItem('check_in_date'),
    check_out_date : localStorage.getItem('check_out_date'),
    location : localStorage.getItem('location'),
    category : localStorage.getItem('category')

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
