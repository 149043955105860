<template>

<Header />





<section style="margin-top: 10px; background-color: #FFFCFC; padding-top: 100px; padding-bottom: 100px;">
	
	<div class="container">

		<div style="padding-bottom: 20px; text-align: left;">
		
		<div class="row">

			<div class="col-md-6  col-6">
				<h3>Search results:({{apartments.length}})</h3>
			</div>

			<div class="col-md-6 col-6" style="text-align:right;">
				<router-link to="/" class="btn btn-1"> <i class="bi bi-search"></i> Search again</router-link>	
			</div>
			
		</div>


		<h4> {{this.$store.state.category}} <span class="color-1">in</span> {{this.$store.state.location}} </h4>
		<p v-if="apartments.length==0">No search results found</p>
	
		</div>

		<div class="row">

			<div class="col-md-3 mt-3" v-for="a in apartments">

				<div class="shadow">
					
					<div :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/apartment-images/'+a.image+')', 'height' : '200px', 'backgroundSize' : 'cover', 'backgroundPosition' : 'center' } "></div>

					<div class="mt-2" style="padding-left: 5px; height: 50px;">
						<h5 class="color-1"> {{a.name}} </h5>
					</div>

					<div style="padding-left: 5px;">
						<p style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{a.address}}</p>
					</div>

					<div style="border-top: solid 1px #f5f5f5; margin-bottom: 10px;"></div>

					<div class="row" style="padding-left:5px; padding-right: 5px; padding-bottom: 10px;">

						<div class="col-md-6 col-6">
							<h4>Ksh.{{a.price}}</h4>
						</div>


						<div class="col-md-6 col-6" style="text-align: right;">
							<router-link :to=" { name : 'apartment-details', params : { id : a.id } } " class="btn btn-outline-success">View</router-link>
						</div>
						
					</div>


					<div style="padding-left:5px; padding-bottom: 5px;">
						<h6> <i class="bi bi-geo-alt color-1"></i> {{a.county}} </h6>
					</div>

				</div>
				
			</div>
			
		</div>

		
	</div>

</section>





<Footer />

</template>




<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'

	
	export default{
		name : 'home',
		components : {Header,Footer},
		data(){
			return{
				apartments : []
			}
		},
		methods : {
			async get_apartments(){
				const res = await axios.get(this.$store.state.url+'api/filter-search/'+this.$store.state.location+'/'+this.$store.state.category).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.apartments = res 
			}
		},

		created(){
			this.get_apartments()
		}
	}

</script>