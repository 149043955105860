<template>

<Header />



<section style="padding-top: 120px;">
	
	<div class="container">

		<div class="row">
			
			<div class="col-md-2"></div>
			<div class="col-md-8">

				<div class="row">

					<div class="col-md-6 col-6">
						<div>
					<h6>Welcome, {{this.$store.state.first_name}}</h6>
				</div>
					</div>



					<div class="col-md-6 col-6" style="text-align:right;">
						<router-link to="/apartments" class="btn btn-outline-success"> Book Apartment + </router-link>
					</div>
					
				</div>



				<div>


					<div>
						<router-link class="btn p-0" to="/dashboard"> <i class="bi bi-arrow-left color-1"></i> Go back</router-link>
					</div>
					


					<h3 class="text-danger">Order#{{order_number}}</h3>

					<h4> Apartment : {{apartment}}  </h4>

					<p> Check in date : {{check_in_date}} </p>
					<p> Check out date : {{check_out_date}} </p>

					

					<div class="row border-top" style="padding-top:20px;">

						<div class="col-md-6">
						<h4> Amount : Ksh.{{amount}} </h4>
						<h5> Total paid : Ksh.{{total_paid}} </h5>

						</div>

						<div class="col-md-6" style="text-align: right;">
							<h4 class="text-danger">Balance : Ksh.{{balance}} </h4>
							<button v-if="balance>0" class="btn btn-1" @click="open_page()">Pay</button>
							<p> {{message}} </p>
						</div>
						
					</div>



					<h6 class="mt-3">Payment history</h6>



					<div class="table-responsive" style="padding-top:0px;">

					<table class="table">

					<thead>
						<tr> <th>Amount</th> <th>Date</th> </tr>
					</thead>

					<tbody>
						
						<tr v-for="p in payments">

							<td> {{p.amount}} </td>
							<td> {{p.created}} </td>
							
						</tr>

					</tbody>
						
					</table>						


					</div>


				</div>




			</div>

		</div>
		
	</div>


</section>




<Footer />





<div style="position:fixed; right: 0; top: 0; width: 100%; padding-top:20px;" v-if="show_page">
	
	<div class="row">

		<div class="col-md-4"></div>
		<div class="col-md-4">
			
		<div style="background-color:#fff;" class="shadow-lg rounded p-2">

		<div class="row">
			<div class="col-md-6 col-6">
				<h6> Order#:{{order_number}} </h6> 
			</div>

			<div class="col-md-6 col-6">
				<div style="text-align: right;">
				<button @click="show_page=false" class="btn text-danger btn-lg"> <i class="bi bi-x"></i> </button>
			</div>
			</div>

		</div>
		

		<div v-html="page"></div>
		</div>

		</div>
		
	</div>

</div>




</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'

	
	export default{
		name : 'home',
		components : {Header,Footer},
		data(){
			return{
				details : [],
				id : '',
				check_in_date : '',
				check_out_date : '',
				order_number : '',
				apartment : '',
				amount : '',
				total_paid : 0,
				balance : 0,
				payments : [],
				paid : false,
				message : '',
				show_page : false

			}
		},

		methods : {


			async open_page(){
	
				this.link = this.$store.state.url+'api/open-page/'+this.order_number+'/'+this.$store.state.first_name+'/'+this.$store.state.last_name+'/'+this.$store.state.email+'/'+this.$store.state.phone+'/'+this.balance

				const res = await axios.get(this.link).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.page = res 
				this.show_page = true
				window.scrollTo({top: 0, behavior: 'smooth'})

				
				setInterval(()=>{
					if(this.paid==false){
					this.check_payment()	
					}else{
						return
					}
					
				},5000)

				

			},

				async check_payment(){
				const res = await axios.get(this.$store.state.url+'api/check-payment/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length>=2){
					this.message = 'Payment  received'
					this.paid = true
					this.show_page = false
					this.record_payment()
					this.order_payments()
					//return 

				}else{
					this.message = 'Waiting for you to make payment'
					
				}

				
			},

			async record_payment(){
				const res = await axios.post(this.$store.state.url+'api/record-payment',{
					order_number : this.order_number,
					amount : this.balance
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
					
			},

			async order_payments(){
				const res  = await axios.get(this.$store.state.url+'api/order-payments/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				
				if(res.length>0){

					res.forEach((data)=>{
					this.total_paid = parseInt(this.total_paid) + parseInt(data.amount)
				})

				this.balance = this.amount - this.total_paid

				this.payments = res

				} 
				 
			},

			async get_details(){
				const res  = await axios.get(this.$store.state.url+'api/order-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.details = res 

				res.forEach((data)=>{
					this.order_number = data.order_number
					this.check_in_date = data.check_in_date
					this.check_out_date = data.check_out_date
					this.apartment = data.apartment_name
					this.amount = data.total_cost
				})

				this.order_payments()
				 
			}

		},

		created(){
			this.id = this.$route.params.id 
			this.get_details()

		}
	}

</script>