<template>
	

<div style="position: fixed; top: 0; right: 0; width: 100%;">
  

  <div class="container">

    <div class="row">



      <div class="col-md-12">
       

<nav class="navbar navbar-expand-lg navbar-ligh bg-ligh rounded  header-design shadow">
  <div class="container-fluid">
    <router-link to="/" class="navbar-brand">
      <img src="/assets/images/new-logo.png" class="logo">
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
        </li>

         <li class="nav-item">
          <router-link to="/apartments" class="nav-link active" aria-current="page">Apartments <b><i class="bi bi-plus color-1"></i></b> </router-link>
        </li>

         <li class="nav-item">
          <router-link to="/about" class="nav-link active" aria-current="page">About</router-link>
        </li>

         <li class="nav-item">
          <router-link to="/contact" class="nav-link active" aria-current="page">Contact</router-link>
        </li>



        
       
      </ul>
      <div class="d-flex">

        <div v-if=" this.$store.state.logged_in=='yes' ">  
        <router-link to="/dashboard" class="btn btn-outline-danger" style="margin-right: 10px;">Dashboard</router-link>      
        <router-link to="/profile" class="btn btn-outline-success zoom" style="margin-right: 10px;">My Profile</router-link>
        <button @click="logout()" class="btn btn-1 zoom">Log Out</button>
        </div>


         <div v-if=" this.$store.state.logged_in!='yes' ">        
        <router-link to="/login" class="btn btn-outline-success zoom" style="margin-right: 10px;">Login</router-link>
        <router-link to="/register" class="btn btn-1 zoom">Register</router-link>
        </div>


      </div>
    </div>
  </div>
</nav>  


      </div>

      
    </div>




</div>


</div>


</template>


<script>
  export default{
    name : 'header',
    methods : {
      logout(){
        this.$store.state.logged_in = 'no'
        localStorage.clear()
        this.$router.push('/login')
      }
    },

    created(){

      if(this.$store.state.logged_in!='yes'){
        this.$router.push('/login')
      }
      
    }

  }
</script>