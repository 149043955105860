import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home'
import About from '../components/About'
import Contact from '../components/Contact'
import ApartmentDetails from '../components/ApartmentDetails'
import Login from '../components/Login'
import ForgotPassword from '../components/ForgotPassword'
import Dashboard from '../components/Dashboard'
import Profile from '../components/Profile'
import Checkout from '../components/Checkout'
import Apartments from '../components/Apartments'
import SearchResults from '../components/SearchResults'
import OrderDetails from '../components/OrderDetails'
import Register from '../components/Register'
import ClientTerms from '../components/ClientTerms'
import AgentTerms from '../components/AgentTerms'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

    {
    path: '/about',
    name: 'about',
    component: About
  },


     {
    path: '/contact',
    name: 'contact',
    component: Contact
  },


     {
    path: '/apartment-details/:id',
    name: 'apartment-details',
    component: ApartmentDetails
  },


     {
    path: '/login',
    name: 'login',
    component: Login
  },

      {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },

      {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },



      {
    path: '/profile',
    name: 'profile',
    component: Profile
  },

      {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },



      {
    path: '/apartments',
    name: 'apartments',
    component: Apartments
  },


       {
    path: '/search-results',
    name: 'search-results',
    component: SearchResults
  },


         {
    path: '/order-details/:id',
    name: 'order-details',
    component: OrderDetails
  },



  {
    path: '/register',
    name: 'register',
    component: Register
  },

    {
    path: '/client-terms',
    name: 'client-terms',
    component: ClientTerms
  },

   {
    path: '/agent-terms',
    name: 'agent-terms',
    component: AgentTerms
  } 



 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
