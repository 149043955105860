<template>

<Header />


<section style="padding-top:150px; padding-bottom: 100px; background-image: url('/assets/images/hero-background-icons.jpeg'); background-size: contain; background-position: top; ">
	

	<div class="container">

		<div class="row">

			<div class="col-md-4 mt-3" style="text-align:center; border-right: solid 1px #f5f5f5;">
				
				<h1 class="animate__animated animate__pulse"> <i class="bi bi-telephone color-1"></i> </h1>
				<h2 class="animate__animated animate__pulse">Phone</h2>
				<p class="animate__animated animate__pulse"> +254 775 606 896  </p>


			</div>


			<div class="col-md-4 mt-3" style="text-align:center; border-right: solid 1px #f5f5f5">
				
				<h1 class="animate__animated animate__pulse"> <i class="bi bi-envelope color-1"></i> </h1>
				<h2 class="animate__animated animate__pulse">Email</h2>
				<p class="animate__animated animate__pulse"> info@safiribykenyans.co.ke   </p>


			</div>

			<div class="col-md-4 mt-3" style="text-align:center;">
				
				<h1 class="animate__animated animate__pulse"> <i class="bi bi-telephone color-1"></i> </h1>
				<h2 class="animate__animated animate__pulse">Location</h2>
				<p class="animate__animated animate__pulse"> 
				Teco Mall, Suite 201,<br />
				Mombasa Road 
				</p>


			</div>

			
		</div>

		
	</div>


</section>

<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'

	
	export default{
		name : 'home',
		components : {Header,Footer}
	}

</script>