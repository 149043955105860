<template>
	
	<section>
	
<div style="text-align: center;">

	<div>
		<img src="/assets/images/new-logo.png" style="height: 50px;">
	</div>

	<div>
		<router-link to="/agent-terms" class="btn color-1">Agent Terms & Conditions</router-link>
		<router-link to="/client-terms" class="btn color-1">Client Terms & Conditions</router-link>
	</div>

	<a href="Instagram: https://www.instagram.com/furnished_apartments_kenya/" class="btn" target="_blank"> <i class="bi bi-instagram"></i> </a>
	<a href="https://www.facebook.com/furnishednairobi/?_rdc=2&_rdr" class="btn" target="_blank"> <i class="bi bi-facebook"></i> </a>
	<a href="https://x.com/SafiriByKenyans" class="btn" target="_blank"> <i class="bi bi-twitter-x"></i> </a>
	<a href="https://ke.linkedin.com/company/safiribykenyans" class="btn" target="_blank"> <i class="bi bi-linkedin"></i> </a>

	<h6>Safiri By Kenyans</h6>
	<h6>Safiri By Kenyans © 2024. All Rights Reserved</h6>

</div>


</section>



<div style="position: fixed; bottom: 0; right: 0; padding-bottom: 30px; padding-right: 20px;">
	
	<a class="btn btn-success btn-lg" href="https://web.whatsapp.com/send?phone=+254775606896&text=Hello!%20I%20need%20your%20service" target="_blank"> Talk To Us <i class="bi bi-whatsapp"></i> </a>

</div>


</template>