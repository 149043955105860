<template>

<Header />



<section style="padding-top:150px; background-image: url('/assets/images/hero-background-icons.jpeg'); background-position: top; ">
	
	<div class="container">

		<div class="row">

			<div class="col-md-1"></div>

			<div class="col-md-5 rounded" style="background-image: url('/assets/images/image-1.jpg'); background-size: cover; background-position: center; "></div>

			<div class="col-md-5" style="padding-top: 100px; padding-bottom: 100px; background-color: none; ">

				
				<div>
					<h3 >Welcome Safiri By Kenyans</h3>
					<h1 class="color-1"><b>Find Your Perfect Stay</b></h1>
					
					<form @submit.prevent="search_now()">
						
						<div>

							<label class="animate__animated animate__pulse">Where?</label>
						<input type="search" class="form-control" placeholder="Search destination" v-model="location">
							
						</div>


						<div class="mt-2">
							<label class="animate__animated animate__pulse">Choose category</label>
							<select class="form-select" v-model="category">
								<option v-for="c in categories">{{c.name}}</option>
							</select>
						</div>


						<div class="mt-3">
							<button class="btn btn-1 w-100"> <i class="bi bi-search"></i> Search</button>
						</div>


						<div class="mt-3">
							<router-link to="/apartments" class="btn zoom">  All apartments <i class="bi bi-arrow-right color-1"></i> </router-link>
						</div>

					</form>

				</div>

			</div>
			
		</div>
		
	</div>


</section>




<section style="margin-top: 100px; background-color: #FFFCFC; padding-top: 100px; padding-bottom: 100px;">
	
	<div class="container">

		<div style="padding-bottom: 20px; text-align: center;">
			<div style="position: absolute; opacity: 0.1; margin-top: -30px; left: 0; right: 0; ">
			<h1 style="color: white; -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black; margin-top: 15px;"> Featured Apartments  </h1>	
			</div>
			<h3 class="color-1">Featured Apartments</h3>
			<h1>Apartments loved by our guests</h1>
		</div>

		<div class="row">

			<div class="col-md-3 mt-3" v-for="a in apartments">

				<div class="shadow">
					
					<div :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/apartment-images/'+a.image+')', 'height' : '200px', 'backgroundSize' : 'cover', 'backgroundPosition' : 'center' } "></div>

					<div class="mt-2" style="padding-left: 5px; height: 50px;">
						<h5 class="color-1"> {{a.name}} </h5>
					</div>

					<div style="padding-left: 5px;">
						<p style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{a.address}}</p>
					</div>

					<div style="border-top: solid 1px #f5f5f5; margin-bottom: 10px;"></div>

					<div class="row" style="padding-left:5px; padding-right: 5px; padding-bottom: 10px;">

						<div class="col-md-6 col-6">
							<h4>Ksh.{{a.price}}</h4>
						</div>


						<div class="col-md-6 col-6" style="text-align: right;">
							<router-link :to=" { name : 'apartment-details', params : { id : a.id } } " class="btn btn-outline-success">View</router-link>
						</div>
						
					</div>


					<div style="padding-left:5px; padding-bottom: 5px;">
						<h6> <i class="bi bi-geo-alt color-1"></i> {{a.county}} </h6>
					</div>

				</div>
				
			</div>
			
		</div>

		
	</div>

</section>




<section style="padding-top:50px; padding-bottom: 100px;">
	

	<div class="container">

		<div style="text-align:center;">
			<h1>Why Choose Us</h1>
		</div>


		<div class="row">

			<div class="col-md-2"></div>
			<div class="col-md-8">
				
				<div style="text-align:center;">


					<div style="background-image:url('/assets/images/elegant-black-girl-winter-city (1).jpg'); width: 100%; background-position: center; background-size:cover; min-height: 400px;" class="rounded mt-3 mb-3 shadow"></div>



					<div style="padding-top:30px;">
						<p>
						 As a registered company in Kenya, Safiri by Kenyans is committed to providing you with the comfort and convenience you need during your travels. Our executive properties are located in major towns and are very affordable, making us the ideal choice for short or long-term stays. 
					</p>
					</div>


					<router-link class="btn zoom" to="/about">Learn More <i class="bi bi-arrow-right color-1"></i> </router-link>
					
				</div>

			</div>
			<div class="col-md-2"></div>
			
		</div>

		
	</div>


</section>

<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'

	
	export default{
		name : 'home',
		components : {Header,Footer},
		data(){
			return{
				apartments : [],
				categories : [],
				location : '',
				category : '',
				markups : []
			}
		},
		methods : {

			search_now(){
				if(!this.location){
					return
				}

				if(!this.category){
					return
				}

				this.$store.state.category = this.category
				this.$store.state.location = this.location

				localStorage.setItem('location',this.location)
				localStorage.setItem('category',this.category)

				this.$router.push('search-results')

			},

			async get_apartments(){
				const res = await axios.get(this.$store.state.url+'api/featured-apartments').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.apartments = res 
			},

			async get_categories(){
				const res = await axios.get(this.$store.state.url+'api/list-categories').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.categories = res 
			},

			async get_markups(){
				const res = await axios.get(this.$store.state.url+'api/get-markups').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.markups = res 
			}
		},

		created(){
			this.get_apartments()
			this.get_categories()
			//this.get_markups()
		}
	}

</script>