<template>


<div :class=" { 'blur' : show_modal } ">
	

<Header />



<section style="padding-top:150px; padding-bottom: 100px;">
	
	<div class="container">

		<div>
			<h6> Home <span class="color-1"> > </span> Apartments <span class="color-1"> > </span> {{name}} </h6>
		</div>

		<div class="row">

			<div class="col-md-8 mt-3">
				<h4> {{name}} </h4>
				<h6> <i class="bi bi-geo-alt"></i> {{address}}, {{ county }} </h6>
			</div>

			<div class="col-md-4 mt-3" style="text-align: right;">
				<button @click="check_login()" class="btn btn-1 zoom">Reserve your apartment stay <i class="bi bi-plus"></i> </button>
			</div>
			
		</div>



<div class="row mt-3">

	<div class="col-md-8">
		<div :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/apartment-images/'+image+')', 'height' : '400px', 'backgroundSize' : 'cover', 'backgroundPosition' : 'center' } " class="rounded"></div>


		<div class="mt-3">
			<button class="btn btn-1 zoom" data-bs-toggle="modal" data-bs-target="#exampleModal"> <i class="bi bi-camera"></i> View gallery</button>
		</div>

	</div>


	<div class="col-md-4">

		<div class="row mt-3">

			<div class="col-md-6 col-6">
				<h6 class="text-danger">Prices:</h6>

				<table class="table table-bordered">
					<tr> <th>Daily :  </th> <td> Ksh. {{daily_price}} </td> </tr>
					<tr> <th>Weekly :  </th> <td> Ksh. {{weekly_admin_price}} </td> </tr>
					<tr> <th>Montly :  </th> <td> Ksh. {{monthly_admin_price}} </td> </tr>

					</table>

			</div>

			<div class="col-md-6 col-6">
				<div style="text-align: right;">
	<button class="btn" data-bs-toggle="modal" data-bs-target="#rules"> <i class="bi bi-eye color-1"></i> House rules </button>
		</div>
			</div>
			
		</div>


		<h3>Amenities</h3>

		<div>
			<p v-if="amenities.length==0" class="text-danger">Not available at the moment</p>
			
			<button style="border:solid 1px #f5f5f5; margin:5px;" class="btn" v-for="a in amenities"> <i class="bi bi-check color-1"></i>  {{ a.name }}  </button>
			
		</div>


		<h3 class="mt-3">Rooms</h3>

		<div class="table-responsive">

			<table class="table">

				<tbody>
		<tr> <th>Bedrooms : </th> <td> {{bedrooms}} </td> </tr>
			<tr> <th>Bathrooms : </th> <td> {{bathrooms}} </td> </tr>
			<tr> <th>Kitchens : </th> <td> {{kitchens}} </td> </tr>
				</tbody>
				
			</table>
			
		</div>

	</div>

	
</div>


		
	</div>

</section>





<Footer />


</div>





<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">  Gallery </h5>
        <button type="button" class="btn-close color-1" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
      	<div>

      		<div :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/'+selected_image+')', 'height' : '400px', 'backgroundSize' : 'cover', 'backgroundPosition' : 'center' } " class="rounded"></div>

      		<div style="overflow: auto; white-space: nowrap" class="mt-3">
      			
      			<label v-for="g in gallery" @click="select_image(g.image)" class="gallery zoom shadow" :style=" { backgroundImage : 'url('+this.$store.state.url+'assets/gallery/'+g.image+')' } "></label>

      		</div>
      	</div>

      </div>


    </div>
  </div>
</div>







<!--rules Modal -->
<div class="modal fade" id="rules" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">  House rules </h5>
        <button type="button" class="btn-close color-1" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
      	<div v-html="rules"></div>

      </div>


    </div>
  </div>
</div>






<div style="position: fixed; right: 0; top: 0; width: 100%; padding-top: 200px;" v-if="show_modal">
	
	<div class="row">

		<div class="col-md-4"></div>
		<div class="col-md-4">
			
			<div style="background-color: #fff; border-radius: 15px; padding: 10px; border: solid #ddd 1px; ">

				<p><span class="color-1">Please note :</span> <br /> Apartment available from {{available_date}} </p>

				<div style="border-top: solid 1px #f1f1f1;"></div>

				<h6 class="mt-2">Choose date</h6>
				<div class="row">
					<div class="col-md-6">
						<label>Check In Date</label>
						<input @change="get_days()" v-model="check_in_date" type="date" class="form-control" :min="available_date">
					</div>

					<div class="col-md-6">
						<label>Check Out Date</label>
						<input @change="get_days()" v-model="check_out_date" type="date" class="form-control" :min="available_date">
					</div>

				</div>

			<div class="border-top mt-3">
					<h6 class="mt-3">Total days : {{days}} <span v-if="display_total>0">| Total amount : Ksh. {{display_total}}</span> </h6>
			</div>


				<div class="mt-3 row">
					
					<div class="col-md-6 col-6">
						<button @click="check_dates()" :disabled="disabled" class="btn btn-1"> {{ text }} </button>
					</div>

					<div class="col-md-6 col-6" style="text-align:right;">
						<button @click="show_modal=false" class="btn text-danger"> <i class="bi bi-x"></i> Close  </button>
					</div>

				</div>
				
			</div>

		</div>
		
	</div>

</div>



</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'
	import Swal from 'sweetalert2'

	
	export default{
		name : 'home',
		components : {Header,Footer},
		data(){

			
			return{

			id : '',
			name : '',
			category : '',
			county : '',
			address : '',
			price : '',
			rules : '',
			kitchens :'',
			bedrooms : '',
			bathrooms : '',
			text : 'Save changes',
			disabled : false,
			image : '',
			gallery : [],
			amenities : [],
			selected_image : '',
			check_in_date : '',
			check_out_date :'',
			text : 'Check Dates',
			disabled : false,
			available_date : '',
			show_modal : false,
			days : 1,
			weekly_admin_price : '',
			monthly_admin_price : '',
			daily_price : 0,
			display_total : 0
			}
			
		},
		methods : {

			get_days(){


				let date1 = new Date(this.check_in_date);
				let date2 = new Date(this.check_out_date);

				// Calculating the time difference
				// of two dates
				let Difference_In_Time =
				    date2.getTime() - date1.getTime();

				// Calculating the no. of days between
				// two dates
				let days =Math.round(Difference_In_Time / (1000 * 3600 * 24));
				if(days>0){
					this.days = days

				}else{
					this.days = 1
				}


				//get markup rates from number of days
				if( this.days<7 ){
					this.price = this.daily_price
				}else if( this.days>=7 && this.days<30 ){
					this.price = this.weekly_admin_price
				}else if( this.days>=30 ){
					this.price = this.monthly_admin_price
				}

				//get total to display
				this.display_total = this.price * this.days 




			},


			async check_apartment_available_date(){

				const res = await axios.get(this.$store.state.url+'api/check-apartment-available-date/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.available_date = res 
				if(this.available_date){
					this.check_in_date = this.available_date
				}

			},

			async check_dates(){
				if(!this.check_in_date){
					return
				}
				if(!this.check_out_date){
					return
				}

				this.text = 'Please Wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/check-apartment-availability',{
					id : this.id,
					check_in_date : this.check_in_date,
					check_out_date : this.check_out_date
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				
				this.text = 'Check Dates'
				this.disabled = false

				if(res=='apartment available'){
					this.$store.state.picked_apartment = this.id 
					localStorage.setItem('picked_apartment', this.id)

					this.$store.state.total_amount = this.price * this.days 
					localStorage.setItem('total_amount', this.price * this.days )

					this.$store.state.days = this.days 
					localStorage.setItem('days', this.days)

					this.$store.state.check_in_date = this.check_in_date
					localStorage.setItem('check_in_date', this.check_in_date)

					this.$store.state.check_out_date = this.check_out_date
					localStorage.setItem('check_out_date', this.check_out_date)

					this.$router.push('/checkout')

				}else{

					Swal.fire({
				  icon: "info",
				  title: "Please note",
				  text: "Apartment not available on the selected dates",
				});

				}


			},

			check_login(){
				if(this.$store.state.logged_in!='yes'){
					Swal.fire({
				  icon: "info",
				  title: "Please note",
				  text: "You must login first",
				});
				}else{
					this.show_modal = true
				}
			},

			select_image(image){
				this.selected_image = 'gallery/'+image 
			},

				async  get_amenities(){
				const res = await axios.get(this.$store.state.url+'api/get-amenities/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.amenities = res		
				 
			},

				async  apartment_gallery(){
				const res = await axios.get(this.$store.state.url+'api/apartment-gallery/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.gallery = res		
				 
			},

				async get_details(){
				const res = await axios.get(this.$store.state.url+'api/apartment-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.details = res

				res.forEach((data)=>{
					this.name = data.name 
					this.category = data.category
					this.county = data.county
					this.address = data.address
					this.daily_price = data.price
					this.weekly_admin_price = data.weekly_admin_price
					this.monthly_admin_price = data.monthly_admin_price
					this.rules = data.description
					this.bathrooms = data.bathrooms
					this.bedrooms = data.bedrooms
					this.kitchens = data.kitchens
					this.image = data.image

				})

				this.selected_image = 'apartment-images/'+this.image 

				this.get_amenities()
		
				 
			}
			
		},

		created(){
		this.id = this.$route.params.id 
		this.get_details()
		this.check_apartment_available_date()
		this.apartment_gallery()
		

			
		}
	}

</script>