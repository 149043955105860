<template>

<Header />


<section style="padding-top:150px; padding-bottom: 100px; background-image: url('/assets/images/hero-background-icons.jpeg'); background-size: contain; background-position: top; ">
	

	<div class="container">

		<div class="row">

		<div class="col-md-4"></div>
		<div class="col-md-4">

			<div style="text-align:center;">
				
				<h4>Checkout</h4>
				<h3 class="text-danger">Ksh.{{amount_to_pay}}  </h3>

				<p class="text-danger">
					{{message}}
				</p>



				<div class="form-check form-check-inline">
  <input @change="get_payment_plan()" v-model="payment_plan" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Pay total" checked>
  <label class="form-check-label" for="inlineRadio1">Pay total</label>
</div>
<div class="form-check form-check-inline">
  <input @change="get_payment_plan()" v-model="payment_plan" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Pay deposit">
  <label class="form-check-label" for="inlineRadio2">Pay deposit</label>
</div>


				<div class="mt-3">
					<button @click="open_page()" class="btn btn-1">Click here to pay</button>
				</div>

				<div class="mt-3">
					<h6>Discount : {{ discount }}% </h6>
				</div>

				<div class="mt-1">
					<h6>Initial amount : Ksh.{{ this.$store.state.total_amount }} </h6>
				</div>



			</div>
			
		</div>

			
		</div>

		
	</div>


</section>

<Footer />




<div style="position:fixed; right: 0; top: 0; width: 100%; padding-top:20px;" v-if="show_page">
	
	<div class="row">

		<div class="col-md-4"></div>
		<div class="col-md-4">
			
		<div style="background-color:#fff;" class="shadow-lg rounded p-2">

		<div class="row">
			<div class="col-md-6 col-6">
				<h6> Order#:{{order_number}} </h6> 
			</div>

			<div class="col-md-6 col-6">
				<div style="text-align: right;">
				<button @click="show_page=false" class="btn text-danger btn-lg"> <i class="bi bi-x"></i> </button>
			</div>
			</div>

		</div>
		

		<div v-html="page"></div>
		</div>

		</div>
		
	</div>

</div>




</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'
	import Swal from 'sweetalert2'

	
	export default{
		name : 'home',
		components : {Header,Footer},
		data(){
			return{
				link : '',
				order_number : '',
				page : '',
				show_page : false,
				paid : false,
				message : '',
				deposit : '',
				amount_to_pay : '',
				payment_plan : '',
				discount : ''
			}
		},

		methods : {

			get_amounts(){
				this.amount_to_pay = this.$store.state.total_amount
				if(this.discount){
					this.amount_to_pay = Math.floor( this.amount_to_pay * ( (100-this.discount)/100 ) )
				}

			},

			get_payment_plan(){
				this.get_amounts()
				if(this.payment_plan=='Pay deposit'){
					this.amount_to_pay = Math.floor( this.amount_to_pay * ( this.deposit /100 ) )
				}

				if(this.payment_plan=='Pay total'){
					this.amount_to_pay = this.amount_to_pay
				}
			},


			async get_settings(){
				const res = await axios.get(this.$store.state.url+'api/get-settings').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.deposit = data.deposit
				})
				
			},


			async get_discounts(){
				this.amount_to_pay = this.$store.state.total_amount

				const res = await axios.get(this.$store.state.url+'api/get-specific-discount/'+this.$store.state.days).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.discount = res 
				this.get_amounts()
			},


				async open_page(){
				this.order_number = Math.floor(Math.random() * 10000) + Math.floor(Math.random() * 567839)


				this.link = this.$store.state.url+'api/open-page/'+this.order_number+'/'+this.$store.state.first_name+'/'+this.$store.state.last_name+'/'+this.$store.state.email+'/'+this.$store.state.phone+'/'+this.amount_to_pay

				const res = await axios.get(this.link).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.page = res 
				this.show_page = true
				window.scrollTo({top: 0, behavior: 'smooth'})

				
				setInterval(()=>{
					if(this.paid==false){
					this.check_payment()	
					}else{
						return
					}
					
				},5000)

				

			},

				async check_payment(){
				const res = await axios.get(this.$store.state.url+'api/check-payment/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length>0){
					this.message = 'Payment  received'
					this.paid = true
					this.submit_order()

				}else{
					this.message = 'Waiting for you to make payment'
					
				}

				
			},



			async submit_order(){

				const form_data = new FormData()
				form_data.append('order_id',this.order_number)
				form_data.append('apartment_id',this.$store.state.picked_apartment)
				form_data.append('first_name',this.$store.state.first_name)
				form_data.append('last_name',this.$store.state.last_name)
				form_data.append('email',this.$store.state.email)
				form_data.append('phone',this.$store.state.phone)
				form_data.append('check_in_date',this.$store.state.check_in_date)
				form_data.append('check_out_date',this.$store.state.check_out_date)
				form_data.append('total_cost',this.$store.state.total_amount)
				form_data.append('days',this.$store.state.days)
				form_data.append('price',this.$store.state.total_amount)
				form_data.append('amount_to_pay',this.amount_to_pay)
				form_data.append('kids',0)
				form_data.append('pets',0)
				form_data.append('adults',0)
				form_data.append('id_number',0)
				form_data.append('payment_plan',this.payment_plan)
				form_data.append('id',this.$store.state.id)

				const res = await axios.post(this.$store.state.url+'api/submit-order',form_data).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Order submitted successfully",
					  showConfirmButton: true,
					})

					this.show_page = false
					this.$router.push('/dashboard')



				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Something went wrong!",
					  showConfirmButton: true,
					})
				}
				
			},


		},

		created(){
			
			this.amount_to_pay = this.$store.state.total_amount
			this.get_settings()
			this.get_discounts()

		}

	}

</script>